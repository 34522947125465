import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Grid,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import Header from "../../components/Header";

const BlogView = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchSocialMediaLinks = async () => {
      try {
        const response = await axios.get(
          `https://admin.pfimage.hasthiya.org/homePage/getLinkById/1`
        );
        if (response.data.status) {
          formik.setValues({
            facebook: response.data.data.fbLink,
            instagram: response.data.data.instagramLink,
            tikTok: response.data.data.tikTokLink,
            youtube: response.data.data.youtubeLink,
          });
        } else {
          throw new Error(response.data.message);
        }
        setLoading(false);
      } catch (err) {
        setError(err);
        setLoading(false);
        setSnackbarMessage("Error fetching social media links");
        setSnackbarOpen(true);
      }
    };

    fetchSocialMediaLinks();
  }, [id]);

  const validationSchema = Yup.object({
    facebook: Yup.string()
      .url("Invalid URL")
      .required("Facebook link is required"),
    instagram: Yup.string()
      .url("Invalid URL")
      .required("Instagram link is required"),
    tikTok: Yup.string().url("Invalid URL").required("TikTok link is required"),
    youtube: Yup.string()
      .url("Invalid URL")
      .required("YouTube link is required"),
  });

  const formik = useFormik({
    initialValues: {
      facebook: "",
      instagram: "",
      tikTok: "",
      youtube: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        await axios.put(
          `https://admin.pfimage.hasthiya.org/homePage/updateLink/1`,
          values
        );
        setSnackbarMessage("Social media links updated successfully");
        setSnackbarOpen(true);
        setTimeout(() => {
          navigate("/social");
        }, 2000);
        setLoading(false);
      } catch (err) {
        setError(err);
        setSnackbarMessage("Error updating social media links");
        setSnackbarOpen(true);
        setLoading(false);
      }
    },
  });

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  if (loading) return <CircularProgress />;
  if (error) return <Typography color="error">Error fetching data</Typography>;

  return (
    <Box m="20px" height="75vh" overflow="auto" paddingRight="20px">
      <Header title="Edit Social Media Links" subtitle="" />

      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              id="facebook"
              name="facebook"
              label="Facebook Link"
              value={formik.values.facebook}
              onChange={formik.handleChange}
              error={formik.touched.facebook && Boolean(formik.errors.facebook)}
              helperText={formik.touched.facebook && formik.errors.facebook}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              id="instagram"
              name="instagram"
              label="Instagram Link"
              value={formik.values.instagram}
              onChange={formik.handleChange}
              error={
                formik.touched.instagram && Boolean(formik.errors.instagram)
              }
              helperText={formik.touched.instagram && formik.errors.instagram}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              id="tikTok"
              name="tikTok"
              label="TikTok Link"
              value={formik.values.tikTok}
              onChange={formik.handleChange}
              error={formik.touched.tikTok && Boolean(formik.errors.tikTok)}
              helperText={formik.touched.tikTok && formik.errors.tikTok}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              id="youtube"
              name="youtube"
              label="YouTube Link"
              value={formik.values.youtube}
              onChange={formik.handleChange}
              error={formik.touched.youtube && Boolean(formik.errors.youtube)}
              helperText={formik.touched.youtube && formik.errors.youtube}
            />
          </Grid>

          <Grid item xs={12} mt={2}>
            <Button
              variant="contained"
              color="secondary"
              type="submit"
              disabled={loading}
            >
              {loading ? (
                <CircularProgress size={24} />
              ) : (
                <strong>Save Changes</strong>
              )}
            </Button>
          </Grid>
        </Grid>
      </form>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={5000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default BlogView;
