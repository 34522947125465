import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Snackbar,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../../components/Header";

const AboutUsView = () => {
  const { id } = useParams();
  const [aboutData, setAboutData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchAboutData = async () => {
      try {
        const response = await axios.get(
          `https://admin.pfimage.hasthiya.org/aboutUs/getById/1`
        );
        if (response.data.status) {
          setAboutData(response.data.data);
          console.log(response.data);
        } else {
          throw new Error(response.data.message);
        }
        setLoading(false);
      } catch (err) {
        setError(err);
        setLoading(false);
        setSnackbarMessage("Error fetching About Us data");
        setSnackbarOpen(true);
      }
    };

    fetchAboutData();
  }, [id]);

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const renderGridItem = (label, value) => (
    <>
      <Grid item xs={2}>
        <Typography variant="body1" fontWeight="bold">
          {label}
        </Typography>
      </Grid>
      <Grid item xs={1}>
        <Typography variant="body1">:</Typography>
      </Grid>
      <Grid item xs={9}>
        <Typography variant="body1">{value}</Typography>
      </Grid>
    </>
  );

  const handleEditClick = () => {
    navigate(`/about-us/edit`);
  };

  if (loading) return <CircularProgress />;
  if (error) return <Typography color="error">Error fetching data</Typography>;

  return (
    <Box m="20px" height="75vh" overflow="auto" paddingRight="20px">
      <Header title={`View About Us`} subtitle="" />

      {aboutData && (
        <Grid container spacing={2}>
          {renderGridItem("Title 1", aboutData.title1)}
          {renderGridItem("Title 2", aboutData.title2)}
          {renderGridItem("Description", aboutData.description)}

          <Grid item xs={12}>
            <Typography variant="body1" fontWeight="bold">
              Image Preview:
            </Typography>
            <img
              src={aboutData.imageURL}
              alt="About Us"
              style={{ width: "20%", marginTop: "10px" }}
            />
          </Grid>

          <Grid item xs={12} mt={2}>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleEditClick}
              sx={{
                backgroundColor: "#6870fa",
                color: "white",
                fontSize: "12px",
                "&:hover": {
                  backgroundColor: "#3e4396",
                },
              }}
            >
              Edit About-Us Details
            </Button>
          </Grid>
        </Grid>
      )}

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={5000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
      />
    </Box>
  );
};

export default AboutUsView;
