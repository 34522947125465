import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import VpnKeyOutlinedIcon from "@mui/icons-material/VpnKeyOutlined";
import { Box, Button, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import Header from "../../components/Header";
import { tokens } from "../../theme";

const Dashboard = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box m="20px" height="80vh" overflow="auto" paddingRight="20px">
      <Box>
        <Header title="DASHBOARD" subtitle="Welcome to your dashboard" />

        <Box mt="20px" display="flex" gap="10px">
          <Link to="/changepw">
            <Button
              sx={{
                backgroundColor: colors.greenAccent[600],
                color: colors.grey[100],
                fontSize: "14px",
                fontWeight: "bold",
                padding: "10px 20px",
                display: "flex",
                alignItems: "center",
                flex: 1,
              }}
            >
              <VpnKeyOutlinedIcon sx={{ mr: "10px" }} />
              Change Password
            </Button>
          </Link>

          <Link to="/changeemail">
            <Button
              sx={{
                backgroundColor: colors.greenAccent[600],
                color: colors.grey[100],
                fontSize: "14px",
                fontWeight: "bold",
                padding: "10px 20px",
                display: "flex",
                alignItems: "center",
                flex: 1,
              }}
            >
              <EmailOutlinedIcon sx={{ mr: "10px" }} />
              Change Email Address
            </Button>
          </Link>
        </Box>
      </Box>
    </Box>
  );
};

export default Dashboard;
