import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Grid,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import Header from "../../components/Header";

const EditAboutUs = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [imagePreview, setImagePreview] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchAboutUsDetails = async () => {
      try {
        const response = await axios.get(
          `https://admin.pfimage.hasthiya.org/aboutUs/getById/1`
        );
        if (response.data.status) {
          formik.setValues({
            title1: response.data.data.title1,
            title2: response.data.data.title2,
            description: response.data.data.description,
            imageURL: response.data.data.imageURL,
          });
          setImagePreview(response.data.data.imageURL);
        } else {
          throw new Error(response.data.message);
        }
        setLoading(false);
      } catch (err) {
        setError(err);
        setLoading(false);
        setSnackbarMessage("Error fetching About Us details");
        setSnackbarOpen(true);
      }
    };

    fetchAboutUsDetails();
  }, [id]);

  const validationSchema = Yup.object({
    title1: Yup.string().required("Title 1 is required"),
    title2: Yup.string().required("Title 2 is required"),
    description: Yup.string().required("Description is required"),
    image: Yup.mixed()
      .test("fileSize", "The file is too large", (value) =>
        value ? value.size <= 1024 * 1024 * 2 : true
      )
      .test("fileType", "Unsupported file format", (value) =>
        value ? ["image/jpeg", "image/png"].includes(value.type) : true
      ),
  });

  const formik = useFormik({
    initialValues: {
      title1: "",
      title2: "",
      description: "",
      image: null,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const formData = new FormData();
      formData.append("title1", values.title1);
      formData.append("title2", values.title2);
      formData.append("description", values.description);
      if (values.image) {
        formData.append("imageURL", values.image);
      }

      setLoading(true);
      try {
        await axios.put(
          `https://admin.pfimage.hasthiya.org/aboutUs/update/1`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        setSnackbarMessage("About Us details updated successfully");
        setSnackbarOpen(true);
        setTimeout(() => {
          navigate("/about");
        }, 2000);
        setLoading(false);
      } catch (err) {
        setError(err);
        setSnackbarMessage("Error updating About Us details");
        setSnackbarOpen(true);
        setLoading(false);
      }
    },
  });

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleImageChange = (event) => {
    const file = event.currentTarget.files[0];
    formik.setFieldValue("image", file);

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  if (loading) return <CircularProgress />;
  if (error) return <Typography color="error">Error fetching data</Typography>;

  return (
    <Box m="20px" height="75vh" overflow="auto" paddingRight="20px">
      <Header title="Edit About Us" subtitle="" />

      <form onSubmit={formik.handleSubmit} encType="multipart/form-data">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              id="title1"
              name="title1"
              label="Title 1"
              value={formik.values.title1}
              onChange={formik.handleChange}
              error={formik.touched.title1 && Boolean(formik.errors.title1)}
              helperText={formik.touched.title1 && formik.errors.title1}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              id="title2"
              name="title2"
              label="Title 2"
              value={formik.values.title2}
              onChange={formik.handleChange}
              error={formik.touched.title2 && Boolean(formik.errors.title2)}
              helperText={formik.touched.title2 && formik.errors.title2}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              id="description"
              name="description"
              label="Description"
              multiline
              rows={4}
              value={formik.values.description}
              onChange={formik.handleChange}
              error={
                formik.touched.description && Boolean(formik.errors.description)
              }
              helperText={
                formik.touched.description && formik.errors.description
              }
            />
          </Grid>
          <Grid item xs={12}>
            <input
              accept="image/*"
              type="file"
              id="image"
              name="image"
              onChange={handleImageChange}
              style={{ display: "none" }}
            />
            <Button
              variant="contained"
              component="label"
              color="secondary"
              sx={{ mt: 2, position: "relative" }}
            >
              Upload Image
              <input type="file" hidden onChange={handleImageChange} />
              {loading && (
                <CircularProgress
                  size={24}
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    color: "white",
                  }}
                />
              )}
            </Button>

            {imagePreview && (
              <Box mt={2}>
                <img
                  src={imagePreview}
                  alt="Preview"
                  style={{
                    width: "200px",
                    height: "200px",
                    objectFit: "cover",
                  }}
                />
              </Box>
            )}
            {formik.errors.image && formik.touched.image && (
              <Typography color="error">{formik.errors.image}</Typography>
            )}
          </Grid>

          <Grid item xs={12} mt={2}>
            <Box display="flex" justifyContent="end" mt="20px">
              <Button
                type="submit"
                color="secondary"
                variant="contained"
                size="large"
                disabled={loading}
                sx={{ position: "relative" }}
              >
                <strong>Update About Us</strong>
                {loading && (
                  <CircularProgress
                    size={24}
                    sx={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      color: "white",
                      zIndex: 1,
                    }}
                  />
                )}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </form>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={5000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default EditAboutUs;
